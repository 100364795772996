export const UAUC = "uauc";
export const NM = "Near_Miss";
export const IRA = "IRA";
export const SUMMARY = "Summary";
export const KPI = "KPI";
export const OVERVIEW = "Overview";
export const COMPLIANCE_FORM = "Compliance_Form";
export const TRAINING = "Training";
export const POLICY_MANAGEMENT = "Policy_Management";
export const AUDIT = "Audit";
export const INSPECTION = "Inspection";
export const CHECKLIST = "Checklist";
export const SURVEY = "Survey";
export const POLL = "Poll";
export const SURVEY_SUGGESTION = "Safety_Suggestion";
export const CONTEST = "Contest";
export const QUIZ = "Quiz";
export const SAFETY_THOUGHTS = "Safety_Thoughts";
export const ARTICLES = "Articles";
export const SAFETY_REVIEW_MEETING = "Safety_Review_Meeting";
export const TOOL_BOX_TALK = "Tool_Box_Talk";
export const TASK_MANAGEMENT = "Task_Management";
export const APPRECIATION_BADGE = "Appreciation_Badge";
export const TOP_PERFORMER = "top_performer";
export const WORK_PERMIT = "Work_Permit";
export const JSA = "JSA";
export const CONTRACTOR = "Contractor";
export const SAFETY_SCORE_BAR = "Safety_Score_Bar";
export const C_TOP_VIEW = "C_Top_View";
export const GOOD_PRACTICES = "good_acts";
export const CART = "Cart";
export const E_LEARN = "E_Learn";
export const GOAL = "Goal";
export const DOS_AND_DONTS = "Dos_Donts";
export const MSDS = "MSDS";
export const STANDARD = "Standard";
export const PSSR = "PSSR";
export const BBS = "BBS";
export const MOCK_DRILL = "mock_drill";
export const CONTRACTOR_ASSESMENT = "Contractor_Assesment";
export const FEEDBACK = "Feedback";
export const SKILL_UP = "Skill_Up";
export const ASSESSMENT = "Assesment";
export const SAFE_MAN_HOURS = "Safe_Man_Hours";
export const COMPARATIVE_ANALYSIS = "Comparative_Analysis";
export const FINANCIAL_ANALYSIS = "Financial_Analysis";
export const H_TRIANGLE = "h_triangle";
export const MAPS_VIEW = "Maps_View";
export const SAFETY_MEETING = "Safety_Review_Meeting";
export const AI = "ai";
export const AI_ALERT = "Ai_Alert";





